.Advert {
    @media ( min-width: 0px ) {
        padding-left: 0;
        padding-right: 0;
    }
    @media ( min-width: 1100px ) {
        padding-left: var(--page-padding-horizontal);
        padding-right: var(--page-padding-horizontal);
    }
    width: 100%;
    > a { width: 100%; }
}
